export const formatAlgoliaResults = (item) => {
    return {
        categoryItemProps: {
            id: Number(item.primaryCategoryId),
            name: item.primaryCategoryName,
            slug: String(item.primaryCategorySlug)
        },
        secondaryCategoryItemProps: {
            name: item.secondaryCategoryName,
            slug: String(item.secondaryCategorySlug) || ''
        },
        tertiaryCategoryItemProps: {
            name: item.tertiaryCategoryName,
            slug: String(item.tertiaryCategorySlug) || ''
        },
        priceItemProps: [
            {
                sponsor: '',
                reviewName: String(item.name),
                linkType: 'default',
                regularPrice: String(item.primaryRegularPrice),
                listPrice: String(item.price),
                unitsNumber: Number(item.primaryLength),
                unitsType: String(item.unitType),
                lifetimePrice: Boolean(item.lifetimeDiscount),
                flashSale: Boolean(item.isFlash),
                topSite: Boolean(item.isTopSite),
                outboundLinkType: ''
            }
        ],

        siteItemProps: {
            catListDescription: String(item.catListDescription),
            hasFlash: Boolean(item.isFlash),
            id: Number(item.objectID),
            isActive: Boolean(item.isActive),
            isTopSite: Boolean(item.isTopSite),
            name: String(item.name),
            promoLabel: '',
            slug: String(item.reviewSlug),
            thumb: String(item.thumb450),
            thumbID: item.thumbId,
            totalScore: Number(item.score),
            content: String(item.content),
            metaDescription: String(item.metaDescription),
            tags: item.tags || [],
            reviewDate: new Date(item.reviewDate).toISOString(),
            downloadsIncluded: Boolean(item.downloadsIncluded),
            moneyBackGuaranteed: Boolean(item.moneyBackGuaranteed),
            isGay: Boolean(item.isGay)
        }
    };
};
