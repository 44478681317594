export const calculateSavings = (discountedPrice: string | null, regularPrice: string | null): number => {
    // Check for null or invalid price strings
    if (!discountedPrice || !regularPrice) {
        return 0; // Return 0 savings if either price is null or invalid
    }

    // Convert price strings to numerical values
    const discountedValue = parseFloat(discountedPrice.slice(1).replaceAll(',', ''));
    const regularValue = parseFloat(regularPrice.slice(1).replaceAll(',', ''));

    // Calculate savings
    let savings = 100 - (100 * discountedValue) / regularValue;
    // Round savings to the nearest integer
    savings = Math.round(savings);

    return savings;
};
