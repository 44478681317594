import styles from '@styles/common-scss/card/_promoButton.module.scss';
import { useRouter } from 'next/router';
import aa from 'search-insights';

interface Props {
    promoCodeLabel?: string; // Make this optional if you want to pass it as needed
    path: string;
    openSiteCategory: string;
    openSitePath: string;
    openSiteSite?: number;
    eventAction?: string;
    qaID?: string;
    createAlgoliaEvent?: any;
    relCategory?: string;
}

const PromoButton = ({
    promoCodeLabel = 'sas', // Default value if not provided
    path,
    openSiteCategory = 'Opensite',
    openSitePath,
    openSiteSite,
    qaID,
    eventAction = 'Opensite',
    createAlgoliaEvent,
    relCategory = 'noopener noreferrer nofollow'
}: Props) => {
    const router = useRouter();

    function reviewAlgoliaEvent(eventName: string) {
        aa('init', {
            appId: process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
            apiKey: process.env.NEXT_PUBLIC_ALGOLIA_PASSWORD,
            useCookie: true,
            cookieDuration: 60 * 60 * 1000
        });

        const algoliaString = sessionStorage.getItem('algoliaQuery');
        const algoliaData = algoliaString ? JSON.parse(algoliaString) : '';

        if (algoliaData && algoliaData.reviewSlug === openSiteSite) {
            aa('convertedObjectIDsAfterSearch', {
                index: 'RRX',
                eventName: String(`${eventName}${router?.asPath ? ` ${router.asPath}` : ''}`).slice(0, 64),
                objectIDs: [`${openSiteSite}`],
                queryID: algoliaData.queryID
            });
        } else {
            aa('convertedObjectIDs', {
                index: 'RRX',
                eventName: String(`${eventName}${router?.asPath ? ` ${router.asPath}` : ''}`).slice(0, 64),
                objectIDs: [`${openSiteSite}`]
            });
            sessionStorage.removeItem('algoliaQuery');
        }
    }

    return (
        <div className={styles.container}>
            <a
                href={path}
                onClick={() =>
                    createAlgoliaEvent ? createAlgoliaEvent('conversion', 'Card Visit Site') : reviewAlgoliaEvent('Review CTA')
                }
                title="Visit Site"
                data-event-category={openSiteCategory}
                data-opensite-path={openSitePath}
                data-event-label={openSiteSite}
                data-event-action={eventAction}
                target="_blank"
                rel={relCategory}
                id={qaID}>
                <div className={styles.button}>
                    <div className={styles.text}>{`Use the code “${promoCodeLabel}” to get our special discount`}</div>
                </div>
            </a>
        </div>
    );
};

export default PromoButton;
