// Importing required modules and types
import { useOnClickOutside } from '@components/partials/common/handleOutsideClick';
import style from '@styles/common-scss/navbar/hamburger-menu/_menu.module.scss';
import Link from 'next/link';
import { useEffect, useState } from 'react';

import { MyContext } from '../../../../../../context/provider';
import { menuParser } from '../../../../../../lib/repository/menuParser';

//import { MenuType } from '../../../../../../lib/types/menuProps';

// Menu component that displays a list of menu items
const Menu = ({ menuRef, setOpen }: any): JSX.Element => {
    // Parsing the menu items from the context
    const { menu, theme } = MyContext();
    const [menuDict, setMenuDict] = useState(menuParser(menu, 'light') || []);

    useOnClickOutside(menuRef, () => setOpen(false));

    useEffect(() => {
        setMenuDict(menuParser(menu, theme));
    }, [menu, theme]);

    return (
        <>
            <div className={style.wrapper} aria-label="Navigation button">
                <ul className={style.menu}>
                    {menuDict?.map((menuItem: Record<string, string>, index: number) =>
                        // Checking if the menu item is a link to an external page
                        menuItem.linkSlug.indexOf('/hareald/') > -1 ? (
                            <li className={`${style.menuItem}`} key={`menu-item-${index}`}>
                                <a
                                    href={process.env.NEXT_PUBLIC_APP_DOMAIN + menuItem.linkSlug}
                                    {...(menuItem.linkRel ? { rel: menuItem.linkRel } : {})}
                                    title={menuItem.linkText}
                                    className={style.menuItemAnchor}
                                    data-event-category="User Action"
                                    data-event-action="Menu Bar"
                                    data-event-label={menuItem.linkText}
                                    style={{ color: menuItem.linkColor ? menuItem.linkColor : '' }}>
                                    {menuItem.linkText.toUpperCase()}
                                </a>
                            </li>
                        ) : (
                            // If the menu item is not a link to an external page, it is a link to a page within the app
                            <li className={`${style.menuItem}`} key={`menu-item-${index}`}>
                                {/* <Link href={process.env.NEXT_PUBLIC_APP_DOMAIN + menuItem.linkSlug}> */}
                                    <a
                                        href={process.env.NEXT_PUBLIC_APP_DOMAIN + menuItem.linkSlug}
                                        {...(menuItem.linkRel ? { rel: menuItem.linkRel } : {})}
                                        title={menuItem.linkText}
                                        className={style.menuItemAnchor}
                                        data-event-category="User Action"
                                        data-event-action="Menu Bar"
                                        data-event-label={menuItem.linkText}
                                        style={{ color: menuItem.linkColor ? menuItem.linkColor : '' }}>
                                        {menuItem.linkText.toUpperCase()}
                                    </a>
                                {/* </Link> */}
                            </li>
                        )
                    )}
                </ul>
            </div>
        </>
    );
};

export default Menu;
