import PromoButton from '@components/partials/common/buttons/promoCodeCTA';
import CheckMark from '@components/svgs/svgCheckMark';
import { MyContext } from '@context/provider';
import { calculateSavings } from '@lib/repository/savingsCalculator';
import { CardDataType } from '@lib/types/cardProps';
import { holidayProps } from '@lib/types/holidayProps';
import { placeHolderCard } from '@lib/web/config';
import styles from '@styles/common-scss/card/_card.module.scss';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { InView } from 'react-intersection-observer';
import aa from 'search-insights';

import VisitSiteCTA from '../buttons/visitSiteCTA';

interface Type {
    site: CardDataType;
    holiday: holidayProps;
    index: number;
    pageTag?: string;
    eventSender?: (arg0: string, arg1: any, arg3: string) => void;
    eventData?: any;
    customHTML?: { description?: boolean; catLink?: boolean };
}

export default function Card({ site, holiday, index, pageTag, eventSender, eventData, customHTML }: Type): JSX.Element {
    const [loading, setLoading] = useState(true);
    const [isDisplay, setIsDisplay] = useState(true);
    const thumbInfo =
        site.siteItemProps.optimizedThumbInfo && site.siteItemProps.optimizedThumbInfo.thumb450
            ? { thumbID: site.siteItemProps.optimizedThumbInfo.id, thumb: site.siteItemProps.optimizedThumbInfo.thumb450 }
            : { thumbID: site.siteItemProps.thumbID, thumb: site.siteItemProps.thumb };
    const [imgSrc, setImgSrc] = useState(`https://assets.rabbitsreviews.com/images/${thumbInfo.thumb.toString()}`);
    const router = useRouter();
    const observedThumb = useRef(null);
    const relCategory = 'nofollow noreferrer';

    //Deal Cards will only show on the /porn/deals page, not even for the search bar
    const { dealIDs } = router && router.pathname === '/porn/deals' && !eventSender ? MyContext() : { dealIDs: [] };

    const updateLinkType = (site) => {
        const { cid, id, deal, tag } = router.query;
        const hasDynamicRoute = cid || tag;
        let filteredData = [];

        if (hasDynamicRoute) {
            const routeParams = [cid, id, deal, tag].filter(Boolean); // Remove null or undefined values
            filteredData = site.priceItemProps.filter((item) => routeParams.includes(item.linkType));
            filteredData.forEach((item) => {
                item.outboundLinkType = cid || tag;
            });
            if (filteredData.length === 0) {
                filteredData = site.priceItemProps.filter((item) => item.linkType === 'default');
                filteredData.forEach((item) => {
                    item.outboundLinkType = cid || tag;
                });
            }
        } else {
            filteredData = site.priceItemProps.filter((item) => item.linkType === 'default');
            filteredData.forEach((item) => {
                item.outboundLinkType = '';
            });
        }

        site.priceItemProps = filteredData;
    };

    // Call the function to update the linkType for the current site
    updateLinkType(site);

    useEffect(() => {
        setLoading(false);
        /**
         * This function checks if the router query contains a 'cid' parameter.
         * If it does, it checks if the router's asPath includes the 'cid' value.
         * @param {NextRouter} router - The router object from Next.js
         * @returns {boolean} - Returns true if the 'cid' is found in the asPath, otherwise false.
         */

        if (router.isReady) {
            const { cid } = router.query;
            if (cid && router.asPath.includes(cid.toString())) {
                setIsDisplay(false);
            }
        }
    }, [router.isReady]);

    // This function finds out the correct event that needs to be sent to the Algolia
    // If the eventSender and eventData is available as props, that means Card is being rendered
    // in the Instant Search panel. In that case, we use those props to send the event. We also put the queryID
    // into a sessionStorage, so that we can send a clickedObjectIDsAfterSearch or convertedObjectIDsAfterSearch
    // event after you navigate to a review page.
    // The other way is sending the clickedObjectIDs or convertedObjectIDs events without the queryIDs
    // @param eventType can be 'conversion' or 'click'
    // @param eventName name to describe where the event is being clicked on ex. 'Card Full Review','Card Thumbnail'
    function createAlgoliaEvent(eventType: string, eventName: string) {
        if (eventSender && eventData) {
            const sessionStorageData = {
                queryID: eventData.__queryID,
                reviewSlug: site.siteItemProps.id
            };
            sessionStorage.setItem('algoliaQuery', JSON.stringify(sessionStorageData));
            eventSender(eventType, eventData, eventName);
        } else {
            aa('init', {
                appId: process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
                apiKey: process.env.NEXT_PUBLIC_ALGOLIA_PASSWORD,
                useCookie: true, // since v2, this is false by default
                cookieDuration: 60 * 60 * 1000 // one hour, in milliseconds (default: 15552000000)
            });
            const algoliaType = eventType === 'click' ? 'clickedObjectIDs' : 'convertedObjectIDs';
            let sortValue = '';
            // If the card is on a Tag or Category page, we need to include the active tab in the event
            // before sending it to Algolia.
            if (router.pathname.indexOf('/porn/categories/') > -1 || router.pathname.indexOf('/porn/tag/') > -1) {
                sortValue =
                    router.query && router.query.sort
                        ? ` ${String(router.query.sort).charAt(0).toUpperCase() + String(router.query.sort).slice(1)}`
                        : ' Relevance';
            }
            // 64 characters is the max we can send to Algolia, that's why there's a split used for the eventName
            aa(algoliaType, {
                index: 'RRX',
                eventName: String(`${eventName}${sortValue}${router && router.asPath ? ` ${router.asPath}` : ''}`).slice(0, 64),
                objectIDs: [`${site.siteItemProps.id}`]
            });
        }
    }

    const thumb = undefined;
    const siteLink = undefined;

    const ThumbLoger = (InView, ThumbID, thumb, siteLink) => {
        if (InView === true && loading === false) {
            if (typeof thumb === 'undefined') thumb = 'il';
            if (typeof siteLink === 'undefined') siteLink = 'p';
            if (typeof ThumbID === 'undefined') ThumbID = 0;
            try {
                const lpath = process.env.NEXT_PUBLIC_API_DOMAIN + '/v1/pthumb/' + ThumbID;
                fetch(lpath, {
                    method: 'POST'
                }).catch(function (e) {
                    // do nothing
                });
            } catch (err) {
                // do nothing
            }
        }
    };
    // Check if the value is null or empty, return ''
    // If it's not null or empty, then return value
    const linkValidator = (value: string) => {
        return value === undefined || value === null || value.trim() === '' ? '' : value;
    };

    /* Holiday substitutions */
    const exclusiveLabel = 'EXCLUSIVE DEAL';
    const lifetimeLabel = 'LIFETIME PRICE';

    // The getBottomCard function generates the bottom section of a card based on the site's properties and promotional details.
    // It takes in parameters: site (containing site details), promoCodeLabel (for displaying promo codes), dealIDs (to check for deals),
    // pageTag (for event tracking), createAlgoliaEvent (for tracking user interactions), and relCategory (for categorization).

    const getBottomCard = (site, promoCodeLabel, dealIDs, pageTag, createAlgoliaEvent, relCategory) => {
        // Check if the site has a promo code
        return site?.siteItemProps?.promoCode ? (
            <>
                {/* If the site is active and has a price, render the promotional card */}
                {site.siteItemProps.isActive && site.priceItemProps[0].regularPrice ? (
                    <article className={`${styles.cardBottom} ${styles.cardColumm}`}>
                        <Link
                            href={
                                process.env.NEXT_PUBLIC_APP_DOMAIN +
                                `/porn/${
                                    dealIDs && dealIDs.indexOf(String(site.siteItemProps.id)) > -1 ? 'deals' : 'reviews'
                                }/${linkValidator(site.siteItemProps.slug).toLowerCase()}`
                            }>
                            {/* Link to the full review or deals page */}
                            <a
                                role="link"
                                tabIndex={site.siteItemProps.id}
                                key={site.siteItemProps.name}
                                data-event-category="User Action"
                                data-event-action={`${pageTag}-sitename-review`}
                                data-event-label={site.siteItemProps.name}
                                title={'Read ' + site.siteItemProps.name + "'s" + ' full review'}
                                className={styles.fullReviewLinkPromo}
                                onClick={() => createAlgoliaEvent('click', 'Card Full Review')}
                                onKeyDown={(e) => e.key === 'Enter' && createAlgoliaEvent('click', 'Card Full Review')}>
                                <span>
                                    <strong>{site.siteItemProps.name}</strong> Full Review
                                    <svg
                                        width="15"
                                        height="12"
                                        viewBox="0 0 15 15"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M5.625 11.25L9.375 7.5L5.625 3.75"
                                            stroke="#216600"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </span>
                            </a>
                        </Link>
                        {/* Promo button for the site */}

                        <PromoButton
                            promoCodeLabel={promoCodeLabel}
                            path={
                                process.env.NEXT_PUBLIC_APP_DOMAIN +
                                `/OpenSite.aspx?SiteID=${site.siteItemProps.id}${site.priceItemProps[0].outboundLinkType !== '' ? `&type=${site.priceItemProps[0].outboundLinkType}` : ''}${dealIDs && dealIDs.indexOf(String(site.siteItemProps.id)) > -1 ? '&linkType=deals-page' : ''}`
                            }
                            relCategory={relCategory}
                            openSitePath={
                                site.priceItemProps[0].flashSale ? `outgoing,${pageTag},flash-cta` : `outgoing,${pageTag},cta`
                            }
                            openSiteCategory="Opensite"
                            openSiteSite={site.siteItemProps.id}
                            eventAction={site.priceItemProps[0].flashSale ? `${pageTag}flash-cta` : `${pageTag}cta`}
                            createAlgoliaEvent={createAlgoliaEvent}
                        />
                    </article>
                ) : (
                    ''
                )}
            </>
        ) : (
            // If no promo code, render a standard card
            <article className={styles.cardBottom}>
                <Link
                    href={
                        process.env.NEXT_PUBLIC_APP_DOMAIN +
                        `/porn/${
                            dealIDs && dealIDs.indexOf(String(site.siteItemProps.id)) > -1 ? 'deals' : 'reviews'
                        }/${linkValidator(site.siteItemProps.slug).toLowerCase()}`
                    }>
                    {/* Link to the full review or deals page */}
                    <a
                        role="link"
                        tabIndex={site.siteItemProps.id}
                        key={site.siteItemProps.nam}
                        data-event-category="User Action"
                        data-event-action={`${pageTag}-sitename-review`}
                        data-event-label={site.siteItemProps.name}
                        title={'Read ' + site.siteItemProps.name + "'s" + ' full review'}
                        className={`${styles.fullReviewLink} ${styles.fullReviewSiteTitle} ${cardsIsActive && site.priceItemProps[0].regularPrice ? styles.fullReviewSiteTitleSmall : styles.fullReviewSiteTitleDefault}`}
                        onClick={() => createAlgoliaEvent('click', 'Card Full Review')}
                        onKeyDown={(e) => e.key === 'Enter' && createAlgoliaEvent('click', 'Card Full Review')}>
                        {site.siteItemProps.name}
                        <br />
                        <span className={styles.noBold}>
                            {dealIDs && dealIDs.indexOf(String(site.siteItemProps.id)) > -1
                                ? 'Deals & Discounts '
                                : 'FULL REVIEW'}
                        </span>
                    </a>
                </Link>
                {/* If the site is active and has a price, render the Visit Site CTA */}
                {cardsIsActive && site.priceItemProps[0].regularPrice ? (
                    <VisitSiteCTA
                        path={
                            process.env.NEXT_PUBLIC_APP_DOMAIN +
                            `/OpenSite.aspx?SiteID=${site.siteItemProps.id}${site.priceItemProps[0].outboundLinkType !== '' ? `&type=${site.priceItemProps[0].outboundLinkType}` : ''}${dealIDs && dealIDs.indexOf(String(site.siteItemProps.id)) > -1 ? '&linkType=deals-page' : ''}`
                        }
                        hasFlash={site.priceItemProps[0].flashSale}
                        relCategory={relCategory}
                        listPrice={site.priceItemProps[0].listPrice}
                        openSitePath={
                            site.priceItemProps[0].flashSale ? `outgoing,${pageTag},flash-cta` : `outgoing,${pageTag},cta`
                        }
                        openSiteCategory="Opensite"
                        openSiteSite={site.siteItemProps.id}
                        eventAction={site.priceItemProps[0].flashSale ? `${pageTag}flash-cta` : `${pageTag}cta`}
                        createAlgoliaEvent={createAlgoliaEvent}
                    />
                ) : (
                    ''
                )}
            </article>
        );
    };

    const getCardClasses = (site, holiday, styles) => {
        let cardClasses = styles.cardContainer;
        let flashLabel = 'FLASH SALE';
        let flashColor = '#EC0014'; // Default color
        let promoCodeLabel = ''; // Default label for promo code

        const hasFlashSale = site.priceItemProps && site.priceItemProps[0]?.flashSale && site.priceItemProps[0].listPrice;
        const hasPromoCode = site?.siteItemProps?.promoCode !== null;

        if (hasFlashSale || hasPromoCode) {
            if (hasFlashSale) cardClasses = styles.flashSaleCard;

            if (holiday.promoLabel && holiday.promoLabel.length > 2) {
                flashLabel = holiday.promoLabel.toUpperCase();
                flashColor = holiday.color; // Use holiday color if available
            }

            if (hasPromoCode) {
                promoCodeLabel = site.siteItemProps.promoCode;
            }
        }

        return { cardClasses: `${cardClasses} ${styles.cardContainer}`, flashLabel, flashColor, promoCodeLabel };
    };

    const { cardClasses, flashLabel, flashColor, promoCodeLabel } = getCardClasses(site, holiday, styles); // Get classes and colors
    const cardsIsActive =
        Array.isArray(site.priceItemProps) &&
        site.siteItemProps.isActive &&
        site.priceItemProps.length > 0 &&
        site.priceItemProps[0] !== null;

    return (
        <>
            <div className={cardClasses} style={{ borderColor: flashColor }}>
                <div className={styles.cardContent}>
                    {/* TOP CARD && LABEL && SCORE */}
                    <div className={styles.cardThumb} ref={observedThumb}>
                        <a
                            href={`${process.env.NEXT_PUBLIC_APP_DOMAIN}${
                                site?.priceItemProps[0]?.regularPrice && cardsIsActive
                                    ? `/OpenSite.aspx?SiteID=${site.siteItemProps.id}${site?.priceItemProps[0]?.outboundLinkType !== '' ? `&type=${site.priceItemProps[0].outboundLinkType}` : ''}${dealIDs && dealIDs.indexOf(String(site.siteItemProps.id)) > -1 ? '&linkType=deals-page' : ''}`
                                    : `/porn/reviews/${linkValidator(site.siteItemProps.slug)}`
                            }`}
                            rel={relCategory}
                            target="_blank"
                            title={'Visit ' + site.siteItemProps.name}
                            data-opensite-action={site?.priceItemProps[0]?.flashSale ? `${pageTag}flashthumb` : `${pageTag}thumb`}
                            data-opensite-category="Opensite"
                            data-opensite-path={
                                site?.priceItemProps[0]?.flashSale
                                    ? `outgoing,${pageTag},flash-thumb`
                                    : `outgoing,${pageTag},thumb`
                            }
                            data-opensite-site={site.siteItemProps.id}
                            onClick={() => createAlgoliaEvent('conversion', 'Card Thumbnail')}>
                            <InView
                                triggerOnce
                                onChange={(InView) => {
                                    ThumbLoger(InView, thumbInfo.thumbID, thumb, siteLink);
                                }}>
                                <Image
                                    unoptimized
                                    alt={`Site thumbnail`}
                                    src={imgSrc}
                                    width={195}
                                    height={105}
                                    layout={'responsive'}
                                    data-thumbid={thumbInfo.thumbID}
                                    priority={index < 3 ? true : false}
                                    onError={() => {
                                        setImgSrc(placeHolderCard);
                                    }}
                                    placeholder="blur"
                                    blurDataURL={placeHolderCard}
                                />
                            </InView>
                            {/* H3 TITLE */}
                            <h3 className={`${styles.cardTitle}`}>{site.siteItemProps.name}</h3>
                        </a>
                        {/* LABELS TOP CARD */}
                        {site?.priceItemProps[0]?.topSite && site?.priceItemProps[0]?.regularPrice ? (
                            <div className={styles.topWebsiteLabel}>RABBITS TOP SITE</div>
                        ) : null}

                        {site.siteItemProps.promoCode ? (
                            <div className={styles.promoLabel}>
                                <div className={styles.promoCodeLabel}>
                                    <svg
                                        width="12"
                                        height="14"
                                        viewBox="0 0 12 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M3.9835 3.01799C3.72159 2.12751 3.45968 1.13228 2.88349 0.608468C2.74041 0.46916 2.5568 0.378879 2.35911 0.350637C2.16142 0.322396 1.95987 0.357651 1.78351 0.451323C0.788269 1.02751 1.05016 2.7037 1.36445 3.85609C1.69922 4.94325 2.13775 5.99571 2.67397 6.99894C1.41683 8.2037 0.683497 9.93228 0.578735 12.3418C0.937895 11.8609 1.4588 11.526 2.04541 11.3989C3.30255 11.2418 3.93113 12.2894 4.66446 12.7608C4.76411 12.4552 4.88667 12.1576 5.03112 11.8704C5.29302 11.9228 5.55492 11.9228 5.76445 11.9751L5.45017 12.918C5.39779 13.0228 5.29303 13.1275 5.45017 13.1799C5.60732 13.2323 5.55493 13.0751 5.60731 12.9704C5.76445 12.6561 5.86922 12.3418 5.97398 12.0799C6.23589 12.1323 6.49779 12.1323 6.70731 12.1847C6.55017 12.6561 6.39303 13.0751 6.23589 13.4942C8.80255 14.1228 11.1073 12.918 11.0549 10.4561C11.0549 8.88466 10.1645 7.9418 9.32636 7.05132C10.2178 5.86498 10.9733 4.58236 11.5787 3.22752C11.8343 2.72295 11.9773 2.16892 11.9978 1.6037C12.0079 1.4452 11.9832 1.28641 11.9255 1.13845C11.8677 0.990493 11.7783 0.856947 11.6636 0.747165C11.5488 0.637383 11.4114 0.55402 11.261 0.502916C11.1106 0.451813 10.9509 0.434204 10.793 0.451323C10.0597 0.503704 9.64064 1.13228 9.27398 1.65609C8.43588 2.91323 7.91207 4.6418 7.44064 6.10847C6.4898 5.82581 5.48867 5.7543 4.50731 5.89894C4.42998 4.92346 4.25449 3.95826 3.9835 3.01799Z"
                                            fill="#216600"
                                        />
                                        <path
                                            d="M3.9835 3.01799C3.72159 2.12751 3.45968 1.13228 2.88349 0.608468C2.74041 0.46916 2.5568 0.378879 2.35911 0.350637C2.16142 0.322396 1.95987 0.357651 1.78351 0.451323C0.788269 1.02751 1.05016 2.7037 1.36445 3.85609C1.69922 4.94325 2.13775 5.99571 2.67397 6.99894C1.41683 8.2037 0.683497 9.93228 0.578735 12.3418C0.937895 11.8609 1.4588 11.526 2.04541 11.3989C3.30255 11.2418 3.93113 12.2894 4.66446 12.7608C4.76411 12.4552 4.88667 12.1576 5.03112 11.8704C5.29302 11.9228 5.55492 11.9228 5.76445 11.9751L5.45017 12.918C5.39779 13.0228 5.29303 13.1275 5.45017 13.1799C5.60732 13.2323 5.55493 13.0751 5.60731 12.9704C5.76445 12.6561 5.86922 12.3418 5.97398 12.0799C6.23589 12.1323 6.49779 12.1323 6.70731 12.1847C6.55017 12.6561 6.39303 13.0751 6.23589 13.4942C8.80255 14.1228 11.1073 12.918 11.0549 10.4561C11.0549 8.88466 10.1645 7.9418 9.32636 7.05132C10.2178 5.86498 10.9733 4.58236 11.5787 3.22752C11.8343 2.72295 11.9773 2.16892 11.9978 1.6037C12.0079 1.4452 11.9832 1.28641 11.9255 1.13845C11.8677 0.990493 11.7783 0.856947 11.6636 0.747165C11.5488 0.637383 11.4114 0.55402 11.261 0.502916C11.1106 0.451813 10.9509 0.434204 10.793 0.451323C10.0597 0.503704 9.64064 1.13228 9.27398 1.65609C8.43588 2.91323 7.91207 4.6418 7.44064 6.10847C6.4898 5.82581 5.48867 5.7543 4.50731 5.89894C4.42998 4.92346 4.25449 3.95826 3.9835 3.01799Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M2.41204 9.20417C2.98837 9.57084 3.56472 9.9375 4.14105 10.2518C3.51232 11.1946 1.83571 10.3513 2.41204 9.20417Z"
                                            fill="#216600"
                                        />
                                        <path
                                            d="M9.64106 9.46608C10.165 10.6708 8.38359 11.3518 7.85965 10.4089C8.48838 10.0947 9.01233 9.78037 9.64106 9.46608Z"
                                            fill="#216600"
                                        />
                                    </svg>

                                    <p className="flashLabel">
                                        {site.priceItemProps[0].flashSale && site.priceItemProps[0].lifetimePrice
                                            ? '...'
                                            : promoCodeLabel}
                                    </p>
                                </div>
                            </div>
                        ) : null}

                        {site?.priceItemProps[0]?.regularPrice &&
                        (site?.priceItemProps[0]?.flashSale || site?.priceItemProps[0]?.lifetimePrice) ? (
                            <div className={styles.promoLabel}>
                                {site.priceItemProps[0].lifetimePrice ? (
                                    <div className={styles.lifeTimeDiscountLabel}>
                                        <CheckMark />
                                        <p className="flashLabel">{lifetimeLabel}</p>
                                    </div>
                                ) : (
                                    ''
                                )}

                                {site?.priceItemProps[0]?.flashSale ? (
                                    <div className={styles.flashSaleLabel}>
                                        <p className="flashLabel">{flashLabel}</p>
                                        <p className="exclusiveLabel hidden">{exclusiveLabel}</p>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        ) : (
                            ''
                        )}
                        {/* Score Box - Bottom Right */}
                        <div className={styles.categoryScoreBox}>
                            <div className={styles.score}>
                                SCORE <br /> <span>{site.siteItemProps.totalScore}%</span>
                            </div>
                            {(site.categoryItemProps.name && isDisplay) || (site.categoryItemProps.slug && isDisplay) ? (
                                <Link
                                    href={
                                        process.env.NEXT_PUBLIC_APP_DOMAIN +
                                        '/porn/categories/' +
                                        linkValidator(site.categoryItemProps.slug).toLowerCase()
                                    }>
                                    <a
                                        className={`${styles.catLink}`}
                                        title={'link to ' + site.categoryItemProps.name + ' sites category'}>
                                        {site.categoryItemProps.name.toUpperCase()}
                                    </a>
                                </Link>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
                {/* Price, description */}
                <div className={`${styles.cardMiddle}`}>
                    {cardsIsActive && site.priceItemProps[0].regularPrice ? (
                        <div
                            className={`${styles.cardPrices}  prd-it-prc prd-it-prc-promo mob-card AB-1644 bottom-hk text-roboto`}>
                            {site.priceItemProps[0].listPrice !== 'N/A' ? (
                                <div className={`${styles.rowPricesSale} col col-1 mob-card AB-1644`}>
                                    <div className={styles.firstRow}>
                                        <div
                                            className={`${
                                                String(site.priceItemProps[0].regularPrice).split('.')[0].length > 2
                                                    ? ''
                                                    : styles.singleDigitSalePrice
                                            } ${styles.salePrice} prd-it-prc-sale`}>
                                            {' '}
                                            {String(site.priceItemProps[0].listPrice).split('.')[0]}
                                            <sup>{String(site.priceItemProps[0].listPrice).split('.')[1]}</sup>
                                        </div>
                                        <div
                                            className={`${styles.regularPrice} ${
                                                String(site.priceItemProps[0].listPrice).split('.')[0].length > 3
                                                    ? styles.longListPrice
                                                    : ''
                                            } prd-it-prc-rg prd-it-prc-onsale`}>
                                            {' '}
                                            {site.priceItemProps[0].regularPrice}
                                        </div>
                                    </div>
                                    <div className={styles.secondRow}>
                                        <div
                                            className={`${
                                                String(site.priceItemProps[0].listPrice).split('.')[0].length > 2
                                                    ? ''
                                                    : styles.singleDigitPrice
                                            } ${styles.priceFrequency} prd-it-prc-fqc mob-card AB-1644`}>
                                            {site.priceItemProps[0].unitsNumber + ' ' + site.priceItemProps[0].unitsType}{' '}
                                        </div>

                                        <p
                                            className={`${styles.prctOff} ${
                                                String(site.priceItemProps[0].listPrice).split('.')[0].length > 3
                                                    ? styles.longListPrice
                                                    : ''
                                            }`}>
                                            SAVE{' '}
                                            {/*  @TODO: Update the calculateSavings function to handle the first argument correctly */}
                                            {calculateSavings(
                                                site.priceItemProps[0].listPrice,
                                                site.priceItemProps[0].regularPrice
                                            ) + '%'}
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                <div className={`${styles.rowPricesRegular} col col-1 mob-card AB-1644`}>
                                    <div
                                        className={`${styles.regularPrice} ${styles.regularPriceNoDiscount} prd-it-prc-rg prd-it-prc-onsale`}>
                                        {' '}
                                        {String(site.priceItemProps[0].regularPrice).split('.')[0]}
                                        <sup>{String(site.priceItemProps[0].regularPrice).split('.')[1]}</sup>
                                    </div>
                                    <div
                                        className={`${styles.priceFrequency} ${styles.priceFrequencyNoDiscount} prd-it-prc-fqc mob-card AB-1644`}>
                                        {site.priceItemProps[0].unitsNumber + ' ' + site.priceItemProps[0].unitsType}{' '}
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        ''
                    )}
                    {/* customHTML payload if exist. Default display description */}
                    {customHTML && customHTML.description === false ? (
                        ''
                    ) : (
                        <p className={`${styles.description} ${site?.priceItemProps[0]?.regularPrice ? '' : styles.longerDesc}`}>
                            {site.siteItemProps.catListDescription}
                        </p>
                    )}
                </div>
                {/* CTAs */}
                {getBottomCard(site, promoCodeLabel, dealIDs, pageTag, createAlgoliaEvent, relCategory)}
            </div>
        </>
    );
}
